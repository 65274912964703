/*--------------------------------
    Call To Action Style  
--------------------------------*/
.clltoaction-style-default {
    padding: 250px 0;
    @media #{$lg-layout} {
        padding: 150px 0;
    }
    @media #{$md-layout} {
        padding: 150px 0;
    }
    @media #{$sm-layout} {
        padding: 150px 0;
    }
    .title {
        font-size: 52px;
        font-weight: 600;
        position: relative;
        margin-bottom: 30px;
        padding-bottom: 0;
        line-height: 1.4;
        color: var(--color-white);
    }
    .inner {
        p {
            color: var(--color-body);
        }
    }
    .subtitle {
        color: #fff;
        font-size: 24px;
        line-height: 1.4;
        margin: 0;
        font-weight: 400;
        letter-spacing: 0.5px;
    }
    .call-to-btn {
        margin-top: 50px;
    }
    .content {
        &.text-start {
            .title {
                &::before {
                    left: 0;
                    transform: none;
                }
            }
            .subtitle {
                padding: 0 16% 0 0;
            }
        }
    }

    &.style-2 {
        padding: 130px 0;
    }

    &.style-3 {
        padding: 150px 0;

        .content-wrapper {
            background-color: var(--color-lessdark);
            border-radius: var(--radius);
            padding: 50px 40px;
        }

        .content {
            .title {
                font-size: 32px;
                margin-bottom: 18px;
                padding-bottom: 10px;
            }

            .subtitle {
                font-size: 16px;
                line-height: 1.4;
                letter-spacing: 0.5px;
            }
        }

        .call-to-btn {
            margin-top: 0;
            @media #{$md-layout} {
                margin-top: 20px;
            }
            @media #{$sm-layout} {
                margin-top: 20px;
            }
        }
    }

    &.style-4 {
        padding: 0;

        .content-wrapper {
            background-color: var(--color-lessdark);
            border-radius: var(--radius);
            padding: 70px 50px;
        }

        .content {
            .title {
                font-size: 47px;
                margin-bottom: 33px;
                padding-bottom: 16px;
                line-height: 1.4;
                @media #{$md-layout} {
                    margin-bottom: 0;
                }
                @media #{$sm-layout} {
                    font-size: 39px;
                    margin-bottom: 1px;
                }
            }

            .subtitle {
                font-size: 16px;
                line-height: 1.4;
                letter-spacing: 0.5px;
            }
        }

        .call-to-btn {
            margin-top: 0;
            @media #{$md-layout} {
                margin-top: 20px;
            }
            @media #{$sm-layout} {
                margin-top: 20px;
            }
        }
    }


    &.style-5 {
        padding: 0;

        .content-wrapper {
            background-color: var(--color-blackest);
            border-radius: var(--radius);
            padding: 70px 50px;
            @media #{$md-layout} {
                padding: 40px 20px;
            }
            @media #{$sm-layout} {
                padding: 40px 20px;
            }
        }

        .content-wrapper-2 {
            background-color: var(--color-blackest);
            border-radius: var(--radius);
            padding: 0 50px;
            @media #{$md-layout} {
                padding: 0 20px;
            }
            @media #{$sm-layout} {
                padding: 0 20px;
            }
        }

        .content {
            .title {
                font-size: 47px;
                margin-bottom: 20px;
                padding-bottom: 0;
                line-height: 1.4;
                @media #{$lg-layout} {
                    font-size: 38px;
                }
                @media #{$md-layout} {
                    font-size: 34px;
                }
                @media #{$sm-layout} {
                    font-size: 34px;
                }
                @media #{$large-mobile} {
                    font-size: 26px;
                }
                &::before {
                    display: none;
                }
            }
            .subtitle {
                font-size: 16px;
                line-height: 1.4;
                letter-spacing: 0.5px;
            }
        }

        .call-to-btn {
            margin-top: 30px;
        }
    }

    &.style-6 {
        padding: 80px 0;
        .content {
            .title {
                font-size: 36px;
                margin-bottom: 0;
                padding-bottom: 0;
                line-height: 1.4;

                &::before {
                    display: none;
                }
            }
        }
        .call-to-btn {
            margin-top: 0;
            @media #{$md-layout} {
                margin-top: 20px;
            }
            @media #{$sm-layout} {
                margin-top: 20px;
            }
        }
    }

    &.style-7 {
        padding: 0;
        .content-wrapper {
            padding: 40px 0;
            border-bottom: 1px solid var(--color-border);
        }
        .content {
            .subtitle {
                font-size: 16px;
                color: var(--color-body);
                max-width: 450px;
                line-height: 30px;
                margin-bottom: 0;
                margin-top: 13px;
                padding: 0;
            }
        }
        .call-to-btn {
            margin-top: 0;
            @media #{$md-layout} {
                margin-top: 20px;
            }
            @media #{$sm-layout} {
                margin-top: 20px;
            }
        }
    }
}


.rn-call-to-action {
    &.style-8 {
        padding: 100px 0;
        background: var(--color-blackest);
        .content-wrapper {
            padding: 40px 0;
            border-bottom: 1px solid var(--color-border);
        }
        .title {
            color: var(--color-white);
        }
        .content {
            .subtitle {
                font-size: 20px;
                color: var(--color-body);
                line-height: 1.5;
                margin-bottom: 0;
            }
        }
        .call-to-btn {
            margin-top: 0;
        }
    }
}

.rn-call-to-action-2 {
    &.style-8 {
        padding: 60px 0;
        background: var(--color-blackest);
        .content-wrapper {
            padding: 40px 0;
            border-bottom: 1px solid var(--color-border);
        }
        .title {
            color: var(--color-white);
        }
        .content {
            .subtitle {
                font-size: 20px;
                color: var(--color-body);
                line-height: 1.5;
                margin-bottom: 0;
            }
        }
        .call-to-btn {
            margin-top: 0;
        }
    }
}



.theme-shape {
    position: relative;
    z-index: 2;
    overflow: hidden;

    &::before {
        position: absolute;
        left: -250px;
        top: 250px;
        right: auto;
        bottom: auto;
        z-index: -1;
        width: 500px;
        height: 500px;
        border-radius: 1000px;
        background-image: linear-gradient(45deg, var(--color-primary), var(--color-light-green));
        opacity: 0.2;
        filter: blur(100px);
        content: "";
    }

    &::after {
        position: absolute;
        z-index: -1;
        width: 500px;
        height: 500px;
        border-radius: 1000px;
        opacity: 0.2;
        filter: blur(100px);
        content: "";
        left: auto;
        top: -250px;
        right: -250px;
        bottom: auto;
        background-image: linear-gradient(45deg, var(--color-secondary), var(--color-tertiary));
        
    }
}