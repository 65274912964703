/*--------------------------
    split Area Styles
----------------------------*/

.rn-splite-style {
  .thumbnail {
    img {
      border-radius: 0;
      width: 100%;
    }

    &.image-left-content {
      img {
        border-radius: 0 10px 10px 0;
      }
    }
  }

  .split-inner {
    padding: 30px 115px;

    @media #{$lg-layout} {
      padding: 0 80px;
    }
    @media #{$md-layout} {
      padding: 0 30px;
    }
    @media #{$sm-layout} {
      padding: 0 30px;
    }

    .title {
      font-size: 36px;
      line-height: 50px;
      color: var(--color-heading);
      font-weight: var(--p-bold);
      @media #{$sm-layout} {
        font-size: 24px;
        line-height: 30px;
      }
    }

    .split-list {
      list-style: none;
      padding-left: 0;
      margin: 0;

      li {
        color: var(--color-dark-alt);
      }
    }
  }

  .split-inner-2 {
    padding: 0 180px;

    @media #{$lg-layout} {
      padding: 0 80px;
    }
    @media #{$md-layout} {
      padding: 0 30px;
    }
    @media #{$sm-layout} {
      padding: 0 30px;
    }

    .title {
      font-size: 36px;
      line-height: 50px;
      color: var(--color-heading);
      font-weight: var(--p-bold);
      @media #{$sm-layout} {
        font-size: 24px;
        line-height: 30px;
      }
    }

    .split-list {
      list-style: none;
      padding-left: 0;
      margin: 0;

      li {
        color: var(--color-dark-alt);
      }
    }
  }

  .split-inner-new {
    padding: 30px 180px;

    @media #{$lg-layout} {
      padding: 40px 80px;
    }
    @media #{$md-layout} {
      padding: 40px 30px;
    }
    @media #{$sm-layout} {
      padding: 40px 30px;
    }

    .title {
      font-size: 36px;
      line-height: 50px;
      color: var(--color-heading);
      font-weight: var(--p-bold);
      @media #{$sm-layout} {
        font-size: 24px;
        line-height: 30px;
      }
    }

    .split-list {
      list-style: none;
      padding-left: 0;
      margin: 0;

      li {
        color: var(--color-dark-alt);
      }
    }
  }
}

.split-inner-form {
  padding: 30px 180px !important;

  @media #{$lg-layout} {
    padding: 40px 80px !important;
  }
  @media #{$md-layout} {
    padding: 40px 30px !important;
  }
  @media #{$sm-layout} {
    padding: 40px 30px !important;
  }
}

.heightImage {
    height: 713px !important;

    @media #{$lg-layout} {
        height: 713px !important;
    }
    @media #{$md-layout} {
        height: 713px !important;
    }
    @media #{$sm-layout} {
        height: 362px !important;
    }
}

.widthImage {
    width: 552px !important;

    @media #{$lg-layout} {
        width: 552px !important;
    }
    @media #{$md-layout} {
        width: 552px !important;
    }
    @media #{$sm-layout} {
        width: 552px !important;
    }
}

.padding-title {
  padding: 3rem 20rem !important;

  @media #{$lg-layout} {
    padding: 3rem !important;
  }
  @media #{$md-layout} {
    padding: 3rem !important;
  }
  @media #{$sm-layout} {
    padding: 3rem !important;
  }
}

.no-radius .rn-splite-style .thumbnail.image-left-content img {
  border-radius: 0;
}






