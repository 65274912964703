/*----------------------
    Blog Styles  
-----------------------*/
.rn-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--color-blackest);
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: var(--radius);
  overflow: hidden;
  transition: all 0.5s;

  .inner {
    .thumbnail {
      a {
        img {
          width: 100%;
          height: 100%;
          object-fit: scale-down;
        }
      }
    }

    .content {
      display: flex;
      flex-wrap: wrap;
      // height: 100%;
      padding: 30px;
      padding-top: 22px;

      @media #{$sm-layout} {
        padding: 30px 20px;
      }
      .descriptiion {
        margin-bottom: 13px;
      }
      .title {
        font-size: 20px;
        line-height: 1.5;
        transition: all 0.5s;
        margin-bottom: 0;
        margin-top: 15px;
        flex-basis: 100%;
      }
    }
  }
  .description-container {
    height: 16em;
    overflow: hidden;
  }
  &.card-list-view {
    height: 100%;
    max-height: 370px;
    .inner {
      display: flex;
      height: 100%;

      .thumbnail {
        flex-basis: 50%;
        a {
          display: block;
          height: 100%;
          img {
            width: auto;
            object-fit: cover;
            height: 100%;
          }
        }
      }
      .content {
        flex-basis: 50%;
        .title {
          margin-bottom: 10px;
        }
        .descriptiion {
          margin-bottom: 13px;
          margin-top: 12px;
          &::after {
            content: "...";
            position: absolute;
            bottom: 10px;
            right: 0;
            padding: 0 1em;
            background-color: #0f0f1100;
          }
        }
      }
    }
  }
}

.rn-meta-list {
  @extend %liststyle;
  font-size: 1.4rem;
  line-height: 2.2rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  li {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--color-gray);
    font-size: 14px;

    a {
      color: var(--color-gray);
      transition: 0.5s;
    }

    &.separator {
      margin: 0 0.8rem;
    }
  }
}



.blogWrapper {
  border-radius: 10px;
  box-shadow: 0 0 40px -10px rgb(67, 245, 245);
  color: white;
  pointer-events: auto;
  z-index: 9;
  transition: all 150ms ease;
  will-change: transform, box-shadow, background;
  &.hidden {
    opacity: 0;
    pointer-events: none;
}
}

.blogWrapper:hover{
  scale: 1.1;
  speed: 1000;
}
